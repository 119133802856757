<template>
  <Layout>
    <BHeader>
      <el-form
        :inline="true"
        :model="formData"
        class="demo-form-inline"
        @submit.native.prevent
      >
        <el-form-item prop="keyWord">
          <el-input
            v-model="formData.keyWord"
            placeholder="用户名"
            clearable
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item prop="keyWord">
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
      <template v-slot:right>
        <el-button
          type="primary"
          @click="handleEditOrCreateUser()"
        >
          新增后台用户
        </el-button>
      </template>
    </BHeader>
    <el-table
      v-loading="userListInfo.loading"
      :data="userListInfo.lists"
      stripe
      highlight-current-row
    >
      <el-table-column
        label="用户ID"
        prop="id"
        min-width="90"
      >
        <template v-slot="{row}">
          <span>{{ row.id }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="用户名"
        prop="name"
        min-width="200"
      >
        <template v-slot="{row}">
          <span>{{ row.userName }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="角色"
        prop="roles"
        min-width="200"
      >
        <template v-slot="{row}">
          <span>{{ row.roleID | rolesID2name(rolesIDMap) }}</span>
        </template>
      </el-table-column>

      <el-table-column
        fixed="right"
        label="操作"
        width="260"
      >
        <template v-slot="{row}">
          <el-button
            size="small"
            @click="handleDelUser(row)"
          >
            删除
          </el-button>
          <el-button
            size="small"
            @click="handleResetPassword(row)"
          >
            重置密码
          </el-button>
          <el-button
            type="primary"
            size="small"
            @click="handleEditOrCreateUser(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="userListInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryOrderList(formData)"
    />
    <el-drawer
      :title="drawerType==='edit'?'Edit User':'New User'"
      :visible.sync="drawerVisible"
      direction="rtl"
      size="650px"
    >
      <el-form
        ref="user"
        :model="currentUser"
        :rules="userRules"
        label-width="100px"
        label-position="left"
      >
        <el-form-item
          label="User Name"
          prop="userName"
        >
          <el-input
            v-model.trim="currentUser.userName"
            placeholder="User Name"
          />
        </el-form-item>
        <el-form-item
          label="User Role"
          prop="roleID"
        >
          <el-select
            v-model="currentUser.roleID"
            placeholder="请选择"
            @change="handleSelectedRole(currentUser.roleID)"
          >
            <el-option
              v-for="(item, index) in rolesListInfo.lists"
              :key="index"
              :label="item.roleName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Preview">
          <PermissionTree
            ref="permissionTree"
            mode="filter"
            :routes="filtersRoutes"
            :show-checkbox="false"
            :default-expand-all="true"
          />
        </el-form-item>
      </el-form>
      <div style="text-align:right;">
        <el-button
          @click="drawerVisible=false"
        >
          取消
        </el-button>
        <el-button
          type="primary"
          :loading="drawerType==='edit' ? editLoading : addLoading"
          @click="handleConfirm"
        >
          确认
        </el-button>
      </div>
    </el-drawer>
  </Layout>
</template>

<script>
import { filterAsyncRoutes } from '@/store/permission'
import asyncRoutes from '@/router/asyncRoutes/gatherRoutes.js'
import PermissionTree from './components/PermissionTree'
import { mapState, mapActions } from 'vuex'

const defaultUser = {
  userName: '',
  roleID: ''
}

export default {
  name: 'UserManagement',
  filters: {
    rolesID2name (id, map) {
      return map[id] ? map[id].roleName || 'unknown' : 'loading...'
    }
  },
  components: {
    PermissionTree
  },
  data () {
    const uniqueUserName = (rule, value, callback) => {
      if (this.drawerType === 'new' && this.userNameList.includes(value)) {
        callback(new Error('用户名已存在'))
      } else {
        callback()
      }
    }
    const requiredRoleId = (rule, value, callback) => {
      if (!this.rolesIDMap[value]) {
        callback(new Error('该ID所匹配的角色不存在'))
      } else {
        callback()
      }
    }
    return {
      formData: {
        keyWord: '',
        pageNum: 1,
        pageSize: 20
      },
      drawerVisible: false,
      drawerType: 'new',
      filtersRoutes: [],
      currentUser: {},
      userRules: {
        userName: [
          { required: true, message: '用户名为必填项' },
          { validator: uniqueUserName, trigger: 'blur' }
        ],
        roleID: [
          { required: true, message: '用户角色为必填项' },
          { validator: requiredRoleId }
        ]
      }
    }
  },
  computed: {
    ...mapState('permission', [
      'userListInfo',
      'rolesListInfo',
      'rolesIDMap',
      'addLoading',
      'editLoading'
    ]),
    userNameList () {
      return this.userListInfo.lists.map(item => {
        return item.userName
      })
    }
  },
  created () {
    this.querySystemUserList(this.formData)
    // 超过100种角色会导致问题, 如果有这种情况, 需修改方案. 当前了解的情况是, 不会超过20个角色, pageSize:100足够安全
    this.queryRolesList({ keyWord: '', pageNum: 1, pageSize: 100 })
  },
  methods: {
    ...mapActions('permission',
      [
        'querySystemUserList',
        'queryRolesList',
        'delSystemUser',
        'resetSystemUserPassword',
        'updateSystemUser',
        'addSystemUser'
      ]),
    handleSearch () {
      this.formData.pageNum = 1
      this.querySystemUserList(this.formData)
    },
    handleEditOrCreateUser (user) {
      const isEdit = !!user
      this.drawerType = isEdit ? 'edit' : 'new'
      this.drawerVisible = true
      this.handleSelectedRole(isEdit ? user.roleID : '')
      this.currentUser = Object.assign({}, isEdit ? user : defaultUser)
      this.$nextTick(() => {
        this.$refs.user.clearValidate()
      })
    },
    handleDelUser (user) {
      this.$confirm(`此操作将删除账号:${user.userName}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delSystemUser(user.id)
          .then(res => {
            if (res.data.code === 200) {
              this.$notify({
                title: '成功',
                message: '删除成功!',
                type: 'success'
              })
              this.querySystemUserList(this.formData)
            }
          })
      })
    },
    handleResetPassword (user) {
      this.$confirm(`此操作将重置:${user.userName} 的密码, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.resetSystemUserPassword(user.id)
          .then(res => {
            if (res.data.code === 200) {
              this.$notify({
                title: '成功',
                message: '重置密码成功!',
                type: 'success'
              })
            }
          })
      })
    },
    handleSelectedRole (roleId) {
      if (!roleId) {
        this.filtersRoutes = []
      } else {
        if (!this.rolesIDMap[roleId]) {
          this.$message({
            type: 'warning',
            customClass: 'zIndex4000',
            message: '该用户所属角色不存在'
          })
        }
        const authRouter = this.rolesIDMap[roleId] ? this.rolesIDMap[roleId].authRouter : []
        const authCode = this.rolesIDMap[roleId] ? this.rolesIDMap[roleId].authCode : []
        const permissionList = authRouter.concat(authCode)
        this.filtersRoutes = filterAsyncRoutes(asyncRoutes, permissionList, '/', true)
      }
      this.$nextTick(() => {
        this.$refs.permissionTree.initRoutesData()
      })
    },
    handleConfirm () {
      this.$refs.user.validate().then(async valid => {
        if (valid) {
          const isEdit = this.drawerType === 'edit'
          let result = {}
          if (isEdit) {
            // eslint-disable-next-line
            const { id, userName, roleID } = this.currentUser
            result = await this.updateSystemUser({ id, userName, roleID })
          } else {
            result = await this.addSystemUser(this.currentUser)
          }
          if (result.code === 200) {
            this.drawerVisible = false
            await this.querySystemUserList(this.formData)
          }
        }
      })
    }
  }
}
</script>

<style  lang="less">
  .el-drawer{
    &:focus{
      outline: none;
    }
    .el-drawer__header{
      >span{
        &:focus{
          outline: none;
        }
      }
      .el-drawer__close-btn{
        &:focus{
          outline: none;
        }
        &:hover{
          color: @primary;
        }
      }
    }
    .el-drawer__body{
      padding: 0 30px 0 20px;
    }
  }
  .zIndex4000{
    z-index: 4000 !important;
  }
</style>

<template>
  <div class="permission-tree">
    <el-tree
      ref="tree"
      :check-strictly="checkStrictly"
      :data="treeData"
      :props="defaultProps"
      :show-checkbox="showCheckbox"
      :default-expand-all="defaultExpandAll"
      node-key="path"
      @check-change="handleCheckChange"
    />
  </div>
</template>

<script>
import {
  generateRoutesPath,
  filterAsyncRoutes,
  generateRoutes
} from '@/store/permission'
import { mapState } from 'vuex'
// import { asyncRoutes } from '@/router'
import { cloneDeep, isEqual } from 'lodash'
import { generateTitle } from '@/utils/i18n'
import asyncRoutes from '@/router/asyncRoutes/gatherRoutes.js'

export default {
  name: 'PermissionTree',
  props: {
    mode: {
      type: String,
      default: 'full'
    },
    routes: {
      type: Array,
      default: () => []
    },
    showCheckbox: {
      type: Boolean,
      default: true
    },
    defaultExpandAll: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      checkStrictly: true,
      originalRoutesData: [],
      routesData: [],
      selectedData: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      tempCheckedKeys: []
    }
  },
  computed: {
    ...mapState('app', ['language']),
    treeData () {
      return this.routesData
    }
  },
  watch: {
    language (val, oldVal) {
      if (val && (val !== oldVal)) {
        this.routesData = this.i18n(this.originalRoutesData)
      }
    }
  },
  created () {
    this.initRoutesData()
  },
  methods: {
    generateTitle,
    initRoutesData () {
      let routes
      if (this.mode === 'full') {
        routes = generateRoutes(asyncRoutes)
      } else {
        routes = generateRoutes(this.routes)
      }
      this.originalRoutesData = routes
      this.routesData = this.i18n(routes)
    },
    i18n (routes) {
      return routes.map(item => {
        const route = cloneDeep(item)
        route.title = this.generateTitle(route.title)
        if (route.children) {
          route.children = this.i18n(route.children)
        }
        return route
      })
    },
    getCheckedKeys () {
      const allData = this.$refs.tree.getCheckedKeys()
      const pathData = allData.filter(item => {
        return typeof item === 'string' && item.startsWith('/')
      })
      const permissionData = allData.filter(item => {
        return !pathData.includes(item)
      })
      return {
        authRouter: pathData,
        authCode: permissionData
      }
    },
    setCheckedKeys (selectedData = []) {
      this.checkStrictly = true
      this.$nextTick(() => {
        const temp = filterAsyncRoutes(asyncRoutes, selectedData)
        this.$refs.tree.setCheckedKeys(generateRoutesPath(temp, selectedData))
        this.checkStrictly = false
      })
    },
    handleCheckChange () {
      const selected = this.getCheckedKeys()
      const checkedKeys = (selected.authCode || []).concat(selected.authRouter || [])
      if (isEqual(checkedKeys, this.tempCheckedKeys)) {
        return false
      }
      this.tempCheckedKeys = checkedKeys
      this.setCheckedKeys(checkedKeys)
    }
  }
}
</script>

<style lang="less">
.permission-tree{
  .el-tree-node__children{
    .el-tree-node__children{
      display: flex;
      flex-wrap: wrap;
      padding-left: 50px;
      & > div{
        & > .el-tree-node__content{
          padding-left: 0!important;
          color: #999999;
        }
      }
    }
  }
}
</style>
